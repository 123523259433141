import {
  ActivatedRouteSnapshot, CanActivate, Resolve,
  Router,
  RouterStateSnapshot, UrlTree,
} from '@angular/router';
import { SidebarConfiguration, SidebarService, UserInfoService } from 'c4p-portal-util';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardRouterGuardService implements CanActivate{

  constructor(
    private sidebarService: SidebarService,
    private userInfoService: UserInfoService,
    private router: Router
  ) {
  }

  public checkLandingPage(){
    const userSettings = this.userInfoService.userSettings;
    const landingPage = userSettings?.pages?.Homepage?.activeDashboard
    if(landingPage){
      this.getDashboardMenuItem(landingPage)
      return false;
    }else{
      this.router.navigate(['dashboard']);
      return false;
    }
  }

  private getDashboardMenuItem(dashboardName: string) {
    this.sidebarService.getSidebarItems().subscribe(res => {
      const dashboardMenu =
        res
          ?.find((x) => x.itemLabel.includes(dashboardName));
      this.router.navigate([this.getRoute(dashboardMenu)]);
    })
    return true;
  }

  private getRoute(menu:SidebarConfiguration ) {
      return menu.route;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLandingPage();
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output, ViewChildren,
  input
} from '@angular/core';
import {
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { of, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TuulaTableSettingsComponent } from './tuula-table-settings/tuula-table-settings.component';
import {
  TuulaTableFilterHeaderColumnComponent
} from './tuula-table-filter-header-column/tuula-table-filter-header-column.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TranslateModule } from '@ngx-translate/core';
import { SideSheetSize, TableColumn, TableFilter, TableUtils } from '../../models';
import { GeneralUtil } from '../../utilities';
import { SideSheetService } from '../../services';
import { NgClass, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TuulaTableSkeletonComponent } from './tuula-table-skeleton/tuula-table-skeleton.component';
import { MatIconModule } from '@angular/material/icon';
import { FunctionCallPipe } from 'tuula-common';
import { ResponsiveService, UserInfoService } from 'c4p-portal-util';
import {  MatSortModule } from '@angular/material/sort';


@Component({
  selector: 'app-tuula-table',
  templateUrl: './tuula-table.component.html',
  styleUrl: './tuula-table.component.scss',
  standalone:true,
  imports:[MatPaginatorModule,TranslateModule,MatTableModule,NgStyle,NgIf,
    NgTemplateOutlet, CdkDropList, CdkDrag,
    NgClass,
    TuulaTableFilterHeaderColumnComponent,
    MatTooltipModule,
    TuulaTableSkeletonComponent,
    MatIconModule,
    FunctionCallPipe,
    MatSortModule
  ]
})
export class TuulaTableComponent implements OnInit {

  dataSource = new MatTableDataSource();

  @Input() columns: TableColumn[];
  @Input() customTitleElement;


  readonly title = input(undefined);
  readonly tableControls = input(undefined);
  readonly tableKey = input(undefined);
  readonly pagination = input(undefined);
  readonly pageSizeOptions = input([5, 10, 20, 50]);
  readonly tableSettingsVisible = input(true);
  readonly tableFilteringButtonVisible = input(true);
  readonly tableLoading = input<boolean>(true);
  readonly showPagination = input<boolean>(true);

  @Output() applyFilter = new EventEmitter<TableFilter[]>();
  @Output() changePage = new EventEmitter<any>();
  @Output() sortChange = new EventEmitter<any>();

  get rows() {
    return this._rows;
  }

  @Input() set rows(value) {
    this._rows = value;
    this.dataSource.data = value;
  };

  @ViewChildren(TuulaTableFilterHeaderColumnComponent) filterInputs;

  tableDisplayedColumns = [];
  tableDisplayedFilterColumns = [];

  tableRowSize = 'default';
  userSettings;
  _rows;
  filterPanelOpened = false;

  totalRowWidth = 0;
  defaultCellWidth = 180;
  filterColumnNamePostfix = "-filter";
  stickyMode = true;
  mobileView = false;

  private readonly pageParamsChangedSubject = new Subject<any>();
  private readonly filterParamsChangedSubject = new Subject<void>();
  private readonly userSettingsChangedSubject = new Subject<void>();

  private readonly destroyed = new Subject<boolean>();

  constructor(
    private userInfoService: UserInfoService,
    private sideSheetService: SideSheetService,
    private responsiveService: ResponsiveService,
  ) {
    this.responsiveService.isMobile$.subscribe((value) => {
      this.mobileView = value;
    });
  }

  ngOnInit() {
    this.columns = this.columns?.filter((column) => {
      return !column.visibilityFunc || column.visibilityFunc();
    });
    this.dataSource.data = this.rows;
    this.getUserSettings();
    this.updateVisibleColumns();
    this.pageParamsChangedSubject.pipe(takeUntil(this.destroyed), debounceTime(500))
      .subscribe((event) => {
        const pagination = this.pagination();
        pagination.page = event.pageIndex  + 1;
        pagination.pageIndex = event.pageIndex;
        pagination.pageSize = event.pageSize;
        this.userSettingsChangedSubject.next();
        this.changePage.emit(pagination);
      });
    this.filterParamsChangedSubject.pipe(takeUntil(this.destroyed), debounceTime(500)).subscribe(()=> {
      const filters = this.convertToFilterModel();
      this.userSettingsChangedSubject.next();
      this.applyFilter.emit(filters);
    });
    this.userSettingsChangedSubject.pipe(takeUntil(this.destroyed), debounceTime(2500)).subscribe(()=> {
      this.updateUserSettings();
    });

    this.onApplyFilter(null);
  }


  convertToFilterModel(): TableFilter[] {
    return this.columns
    .filter(col => (col?.enableFiltering && (!GeneralUtil.isEmpty(col?.filter?.filterValue) || col?.filter?.filterDefaultValue) ))
    .map(col=> {
      return  {
        filterProp: col?.filter?.filterProp ?? col.prop,
        filterValue: col?.filter?.filterValue ?? col?.filter?.filterDefaultValue,
        filterValueIntervalEnd: col?.filter?.filterValueIntervalEnd
      }
    });
  }

  getNestedValue({row, column}) {
    const val = column.prop.split('.').reduce((o, i) => (o && o[i] !== undefined) ? o[i] : undefined, row);
    if (column.pipe) {
      return column.pipe.transform(val);
    }
    return val;
  }

  drop(event: CdkDragDrop<string[]>) {
    const previousProp = this.tableDisplayedColumns[event.previousIndex];
    const currentProp = this.tableDisplayedColumns[event.currentIndex];
    const leftmostColumns = this.columns.filter((column) => column.frozenLeft === true && column.permanent === true && column.hideOnSettings === true);
    const rightmostColumns = this.columns.filter((column) => column.frozenRight === true && column.permanent === true && column.hideOnSettings === true);
    const findPreviousColumnIndex = this.columns.findIndex((column) => column.prop === previousProp);
    const findCurrentColumnIndex = this.columns.findIndex((column) => column.prop === currentProp);
    if (leftmostColumns.length > 0 && findCurrentColumnIndex < leftmostColumns.length) {
      moveItemInArray(this.columns, findPreviousColumnIndex, findCurrentColumnIndex + leftmostColumns.length);
    } else if (rightmostColumns.length > 0 && findCurrentColumnIndex >= this.columns.length - rightmostColumns.length) {
      moveItemInArray(this.columns, findPreviousColumnIndex, findCurrentColumnIndex - rightmostColumns.length);
    } else {
      moveItemInArray(this.columns, findPreviousColumnIndex, findCurrentColumnIndex);
    }
    this.updateVisibleColumns();
  }

  onPageChanged(event) {
    this.pageParamsChangedSubject.next(event);
  }

  onSortChanged(event) {
    const pagination = this.pagination();
    pagination.sortColumn = event.active;
    pagination.sortDirection = event.direction;
    this.sortChange.emit(pagination);
  }

  onApplyFilter(event: any) {
    this.filterParamsChangedSubject.next();
  }

  updateVisibleColumns() {
    let tempRowWidth = 0;
    const tempFilterColumns = [];

    this.tableDisplayedColumns = this.columns.filter((column) => !column.hidden).map((column) => {
      tempRowWidth += (column.width? column.width : this.defaultCellWidth);
      tempFilterColumns.push(column.prop + this.filterColumnNamePostfix);
      return column.prop;
    });

    this.tableDisplayedFilterColumns = tempFilterColumns;
    this.totalRowWidth = tempRowWidth;

    this.userSettingsChangedSubject.next();
  }

  getUserSettings() {
    const tableKey = this.tableKey();
    if (tableKey && this.tableSettingsVisible() === true) {
      this.userSettings = this.userInfoService.getTableViewForPage(tableKey);

      if (this.userSettings?.pageSize && this.pagination()) {
        const pageSizeOptions = this?.pageSizeOptions();
        this.pagination().pageSize = pageSizeOptions?.includes(this.userSettings.pageSize) ? this.userSettings.pageSize : pageSizeOptions?.[0];
      }

      if (this.userSettings?.tableRowSize) {
        this.tableRowSize = this.userSettings.tableRowSize;
      }

      if (this.userSettings?.stickyMode !== undefined) {
        this.stickyMode = this.userSettings.stickyMode;
      }

      if (this.userSettings?.columns) {
        const map = this.columns.reduce((acc, item) => {
          acc.set(item.prop, item);
          return acc;
        }, new Map<string, TableColumn>());

        const columns = this.userSettings?.columns.map((col) => {
          if (map.has(col.prop)) {
            const foundColumn = map.get(col.prop);
            foundColumn.hidden = col.hidden;
            foundColumn.frozenLeft = col.frozenLeft;

            if (foundColumn?.enableFiltering && foundColumn?.filter && col.filter) {
              this.filterPanelOpened = true;
              foundColumn.filter = { ...foundColumn.filter, ...col.filter }
            }
            map.delete(col.prop);

            return foundColumn;
          }
          return null;
        }).filter((column) => column !== null);

        map.forEach((value, key) => {
            columns.push(value);
        });

        const { leftMostCols, centerCols, rightMostCols } = TableUtils.reorderPermanentColumns(columns);
        this.columns = [...leftMostCols, ...centerCols, ...rightMostCols];
      }
    }
  }

  updateUserSettings() {
    const tableKey = this.tableKey();
    if (tableKey && this.userSettings) {
      const columnCopy = this.columns.map((column) => {
        const col: any = {
          prop: column.prop,
          hidden: column.hidden,
          frozenLeft: column.frozenLeft,
        };

        if  (column?.enableFiltering && !GeneralUtil.isEmpty(column?.filter?.filterValue)) {
          const filter: any = {
            filterValue: column?.filter?.filterValue,
            filterValueIntervalEnd: column?.filter?.filterValueIntervalEnd,
            selectedOptionObject: column?.filter?.selectedOptionObject,
            selectedOptionObjects: column?.filter?.selectedOptionObjects
          };
          col.filter = filter;
        }
        return col;
      });
      this.userInfoService.setTableViewForPage(tableKey, {
        columns: columnCopy,
        ...(this.pagination() && { pageSize: this.pagination().pageSize }),
        tableRowSize: this.tableRowSize,
        stickyMode: this.stickyMode,
      });    }
  }

  onToggleFilter() {
    this.filterPanelOpened = !this.filterPanelOpened;
  }

  onOpenTableSettings() {
    const button = this.sideSheetService.createSaveButton();
    const sideSheetRef = this.sideSheetService.loadComponent(
      TuulaTableSettingsComponent,
      {title: 'general.labels.TableViewPreferences', data: {columns: this.columns, tableRowSize: this.tableRowSize, stickyMode: this.stickyMode}, buttons: [button]},
      {
        size: SideSheetSize.SM,
        breadcrumbBackButtonVisible: false,
        commandLineVisible: true
      },
      false,
    );
    sideSheetRef.afterClosed().subscribe(data => {
      if (data) {
        this.tableRowSize = data.tableRowSize;
        this.stickyMode = data.stickyMode;
        this.columns = [...data.columns];
        this.updateVisibleColumns();
      }
    });
  }

  onClearFilter() {
    this.filterInputs.forEach((filterInput) => {
        filterInput.clearFilters();
    });
  }
}

import { Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { Subject } from 'rxjs';
import { SideSheetService } from '../../../services';
import {
  ActionType,
  SIDE_SHEET_CONFIG,
  SIDE_SHEET_CONTENT,
  SideSheetButtonEvent,
  SideSheetButtonListener,
  SideSheetConfig,
  SideSheetContent, SideSheetTooltipButtonEvent,
  SideSheetTooltipButtonListener,
  TableColumn, TableUtils,
} from '../../../models';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDragPlaceholder,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';


@Component({
  selector: 'app-tuula-table-settings',
  templateUrl: './tuula-table-settings.component.html',
  styleUrl: './tuula-table-settings.component.scss',
  standalone:true,
  imports: [  
    CdkDrag,
    CdkDragHandle,
    CdkDragPlaceholder,
    CdkDropList,
    TranslateModule,
    NgClass,
    MatSlideToggleModule
  ]
})
export class TuulaTableSettingsComponent implements OnInit, OnDestroy, SideSheetButtonListener, SideSheetTooltipButtonListener {


  private readonly destroyed$ = new Subject<boolean>();
  columns: TableColumn[] = [];
  leftmostColumns: TableColumn[] = [];
  rightmostColumns: TableColumn[] = [];
  pristine = true;

  tableRowSize: string;
  stickyHeader = true;

  constructor(
    private sideSheetService: SideSheetService,
    @Inject(SIDE_SHEET_CONTENT) public sideSheetContent: SideSheetContent,
    @Inject(SIDE_SHEET_CONFIG) public sideSheetConfig: SideSheetConfig,
  ) { }

  ngOnInit(): void {
    const { leftMostCols, centerCols, rightMostCols } = TableUtils.reorderPermanentColumns(this?.sideSheetContent?.data?.columns);
    this.columns = centerCols;
    this.leftmostColumns = leftMostCols;
    this.rightmostColumns = rightMostCols;
    this.tableRowSize = this?.sideSheetContent?.data?.tableRowSize;
    this.stickyHeader = this?.sideSheetContent?.data?.stickyMode;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onToggleColumn(column) {
    column.hidden = !column.hidden;
    this.pristine = false;
  }

  onPinColumn(column) {
    column.frozenLeft = !column.frozenLeft;
    this.pristine = false;
  }

  onTableRowSizeChange(event) {
    this.tableRowSize = event;
    this.pristine = false;
  }

  onStickyHeaderChange(event) {
    this.stickyHeader = event;
    this.pristine = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.pristine = false;
  }

  onClickButton(event: SideSheetButtonEvent) {
    if (event?.button?.actionType === ActionType.SIDE_SHEET_CLOSE) {
      this.sideSheetService.closeSideSheet();
    } else if (event?.button?.actionType === ActionType.BACKDROP_CLICK) {
      if (!this.pristine) {
        const action = this.sideSheetService.createConfirmAction(
          event?.button,
          'general.labels.UnsavedChanges',
          'general.messages.UnsavedChangesMessage',
        );
        this.sideSheetService.openTooltip(action);
      } else {
        this.sideSheetService.closeSideSheet();
      }
    }

    if (event?.button?.actionType === ActionType.SAVE) {
      this.sideSheetService.closeComponent({columns: [...this.leftmostColumns, ...this.columns, ...this.rightmostColumns], tableRowSize: this.tableRowSize, stickyMode: this.stickyHeader});
    }
  }

  onClickTooltipButton(event: SideSheetTooltipButtonEvent) {
    if (event?.button?.actionType === ActionType.BACKDROP_CLICK && event?.confirmed) {
      this.sideSheetService.closeSideSheet();
    }
  }
}
